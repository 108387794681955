html.zd-marketing {
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote,
  button,
  p,
  a,
  input,
  select,
  ::placeholder {
    color: var(--colour-brand-off-black);
    font-family: var(--font-new) !important;
  }

  body {
    -ms-overflow-style: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button {
    font-weight: bold;
  }

  a {
    color: var(--colour-main);
  }

  .noscroll {
    overflow: hidden;
  }

  .TextContent-body,
  .Select-select,
  .Select-value {
    font-family: var(--font-new) !important;
  }

  strong,
  .strong {
    font-weight: bold;
    font-family: var(--font-new);
  }

  ::selection {
    background: color(var(--colour-main) a(40%));
  }

  .title-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: var(--content-padding-mobile);
    padding-left: var(--content-padding-mobile);
  }

  .title {
    margin-bottom: var(--content-padding-titles);
    font-weight: bold;
    font-size: 1.875rem;
  }

  .subtitle {
    width: 100%;
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-copy);
    line-height: var(--font-size-hook-desktop);
  }

  p {
    margin: 0;
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-copy);
    line-height: var(--font-size-hook-desktop);
  }

  @media (--small) {
    .title {
      font-size: 2.5rem;
    }

    .subtitle {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }

  @media (--xlarge) {
    .title-wrap {
      align-items: center;
      width: var(--content-width-large);
      margin: 0 auto;
      padding: 0;
    }
  }

  @media (--xxxlarge) {
    .title-wrap {
      width: 56.25rem;
    }
  }

  @media (--medium) {
    a[href^='tel:'] {
      color: var(--colour-brand-off-black);
      text-decoration: none;
      pointer-events: none;
    }
  }
}
