/**
 * @define Select
 */

.Select {
  position: relative;
  display: inline-block;
  width: var(--input-width-default);
  padding-top: 15px;
  overflow: hidden;
  vertical-align: top;
  transform: translateZ(0);
  backface-visibility: hidden;

  &-field {
    width: 100%;
  }

  &-value:empty::after {
    line-height: 1.1;
    content: ' ';
  }

  &-container {
    @mixin inputSimple;
    position: relative;
    width: 100%;
    min-height: var(--input-min-height);
    padding: var(--gutter-small) 0 var(--gutter-small);

    border: none;
    color: var(--colour-grey-mid);
    font-size: var(--font-size-input);
    font-family: var(--font-body);
    line-height: 1;

    &::after {
      @mixin icon;
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 1;
      color: var(--colour-grey-mid);
      vertical-align: top;
      transform: translateY(-50%);
      transition: color 0.2s ease;
      content: var(--icon-menu-down);
    }

    &:hover::after {
      color: var(--colour-grey-dark);
    }
  }

  html.zd-theme-tenants &-container {
    font-family: var(--font-new) !important;
  }

  &.has-value &-container {
    color: black;
  }

  &.is-focused {
    transform: none;

    .Select-container {
      border-color: var(--colour-primary);

      &::after {
        color: var(--colour-primary);
      }

      html.zd-theme-tenants & {
        border-color: var(--colour-main);

        &::after {
          color: var(--colour-main);
        }
      }

      html.zd-theme-agents & {
        border-color: var(--colour-agents);

        &::after {
          color: var(--colour-agents);
        }
      }
    }
  }

  &-select {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 110%;
    height: 100%;
    font-size: var(--font-size-normal);
    cursor: pointer;
    opacity: 0;
    appearance: menulist-button;
  }

  &-label {
    position: absolute;
    top: -12px;
    left: 0;
    display: block;
    color: var(--colour-grey-mid);
    font-size: var(--font-size-small);
    line-height: 1;
    transform: translateY(5px);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  &-border {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 1px;
    background-color: var(--colour-grey-mid);
    pointer-events: none;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background-color: var(--colour-grey-dark);
      transform: scaleX(0) translateZ(0);
      transform-origin: 0 0;
      backface-visibility: hidden;
      transition: all 0.2s ease-out;
      content: '';
      pointer-events: none;
    }
  }

  html.zd-theme-tenants &-select:not(:disabled) ~ &-border {
    border-bottom-color: var(--colour-main);

    &::after {
      background-color: var(--colour-main);
    }
  }

  html.zd-theme-agents &-select:not(:disabled) ~ &-border {
    background-color: var(--colour-agents);

    &::after {
      background-color: var(--colour-agents);
    }
  }

  &-select:hover ~ &-border::after {
    background-color: var(--colour-grey-dark);
    transform: scaleX(1) translateZ(0);
  }

  &-select:focus ~ &-border::after {
    background-color: var(--colour-primary);
    transform: scaleX(1) translateZ(0);

    html.zd-theme-agents & {
      background-color: var(--colour-agents);
    }

    html.zd-theme-tenants & {
      background-color: var(--colour-main);
    }
  }

  &-select:focus ~ &-label {
    color: var(--colour-primary);

    html.zd-theme-agents & {
      color: var(--colour-agents);
    }

    html.zd-theme-tenants & {
      color: var(--colour-main);
    }
  }

  &.has-value &-label {
    transform: translateY(0);
    opacity: 1;
  }

  &.is-error &-container,
  html.zd-theme-tenants &.is-error &-container,
  html.zd-theme-agents &.is-error &-container {
    border-color: var(--colour-error);

    &::after {
      color: var(--colour-error);
    }

    .Select-select:focus ~ .Select-label {
      color: var(--colour-error);
    }

    .Select-select:focus ~ .Select-border::after {
      background-color: var(--colour-error);
    }
  }

  /* helpers (errors and hint text) */

  &-helpers {
    position: relative;
    z-index: 0;
    font-size: var(--font-size-small);

    &::after {
      left: 0;
      display: block;
      width: 100%;
      padding: var(--gutter-smaller) 0 var(--gutter-smaller);
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      color: var(--colour-grey-mid-dark);
      font-size: var(--font-size-small);
      line-height: 1.2em;
      transition: opacity var(--transition-default);
      content: var(--unicode-nbsp);
      pointer-events: none;
    }

    &[data-hint]:not([data-hint=''])::after {
      content: attr(data-hint);
    }

    &[data-hint]:not([data-hint='']) {
      margin-bottom: var(--gutter);
    }
  }

  &.is-error {
    .Select-select {
      border-color: var(--colour-error);
      background-color: color(var(--colour-error) lightness(98%));
    }

    .Select-label {
      color: var(--colour-error);
    }

    .Select-border {
      border-bottom-color: var(--colour-error) !important;

      &::after {
        background-color: var(--colour-error) !important;
      }
    }

    .Select-container::after {
      color: var(--colour-error);
    }

    .Select-helpers::after {
      color: var(--colour-error);
      font-weight: normal !important;
      font-family: var(--font-headings);
      text-align: right;
    }

    .Select-helpers[data-error]:not([data-error=''])::after {
      color: var(--colour-error);
      content: attr(data-error);
    }
  }

  &-hiviz {
    overflow: initial;
  }

  &-hiviz.is-error {
    .Select-helpers {
      margin-bottom: var(--gutter);
    }

    .Select-helpers::after {
      box-sizing: border-box;
      padding: var(--gutter-medium);
      padding-bottom: calc(var(--gutter-medium) - 2px);
      border-radius: var(--border-radius-toast);
      color: var(--colour-white) !important;
      font-size: var(--font-size-normal);
      text-align: left !important;
      background-color: var(--colour-error);
      transform: translateY(var(--gutter-small));
    }

    .Select-helpers::before {
      position: absolute;
      top: var(--gutter-smaller);
      left: var(--gutter-medium);
      display: block;
      width: 0;
      height: 0;
      border-right: 5px solid transparent;
      border-bottom: 5px solid var(--colour-error);
      border-left: 5px solid transparent;
      content: '';
    }
  }

  /* disabled state */

  &-select:disabled ~ &-label {
    transform: translateY(0);
    opacity: 1;
  }

  &-select:disabled ~ &-border {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    background-color: transparent;

    &::after {
      display: none;
    }
  }

  &-select:disabled {
    pointer-events: none;
  }

  /* spanning container and label positioning */

  &--span {
    width: 100%;

    .Select-field {
      width: 100%;
    }
  }

  /* margins for series of selects */

  &:not(:last-child):not(.u-block) {
    margin-right: var(--gutter-small);
  }
}
