/**
 * @define DocumentIcons
 */

.DocumentIcons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 0;

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    margin-top: 30px;
    padding: var(--gutter) 30px;
    border-radius: 3px;
    color: white;
    font-size: initial;
    text-align: center;
    text-decoration: none;
    background-color: var(--colour-main);
    cursor: pointer;
    transition: all var(--transition-default);

    &:hover {
      background-color: var(--colour-accent);
    }

    @media (--mediumlarge) {
      width: 50%;
    }
  }

  &-item:not(:last-child) {
    @media (--mediumlarge) {
      margin-right: 30px;
    }
  }

  &-icon {
    display: block;
    padding: 10px 0 0;
  }

  &-label {
    display: block;
    max-width: 200px;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
  }

  @media (--mediumlarge) {
    flex-direction: row;
    justify-content: flex-start;
  }
}
