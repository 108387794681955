/**
* @define HowItWorks
*/

.HowItWorks {
  &-contents {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: var(--gutter-xxlarge);
    padding-bottom: var(--gutter-large);

    &:not(:first-child) {
      padding-top: var(--gutter-large);
    }

    &:last-child {
      @media (--medium) {
        padding-bottom: var(--gutter-xxlarge);
      }
    }

    @media (--medium) {
      flex-wrap: none;
      padding-top: var(--gutter-xxlarge);
      padding-bottom: var(--gutter-large);
    }
  }

  &-title {
    flex: 1 0 100%;
    font-weight: bold;
    font-size: var(--font-size-hook-desktop);
    font-family: var(--font-new);
  }

  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    margin-right: -var(--gutter);
    margin-left: -var(--gutter);
    padding: var(--gutter-large) var(--gutter);
    overflow-x: auto;
    font-weight: var(--font-weight-light);

    @media (--medium) {
      flex-direction: row-reverse;
      align-items: center;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      padding: 0;
      overflow: hidden;
    }

    &-title {
      position: relative;
      flex: 0 0 70%;
      max-width: 350px;
      margin-right: var(--gutter-large);
      padding-right: var(--gutter);
      font-size: var(--font-size-hook);
      line-height: 1em;

      &::before {
        position: absolute;
        top: 0;
        right: 0;

        bottom: 0;
        width: 2px;
        background-color: var(--colour-main);
        content: '';
      }

      @media (--medium) {
        flex-basis: 50%;
        max-width: unset;
        margin-right: 0;
        padding: 0 0 0 var(--gutter-xlarge);
        font-size: var(--font-size-hook-desktop);

        &::before {
          top: -var(--gutter);
          right: unset;
          bottom: -var(--gutter);
          left: 0;
        }
      }
    }

    &:nth-child(even) &-title {
      @media (--medium) {
        &::before {
          right: 0;
          left: unset;
        }
      }
    }

    &:not(:first-child) {
      margin-top: var(--gutter-large);

      @media (--medium) {
        margin-top: var(--gutter-xlarge);
      }
    }

    &-list {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: flex-start;

      &-item {
        flex: 0 0 80vw;
        max-width: 450px;
        padding: 0 var(--gutter-large) 0 0;

        @media (--medium) {
          flex-basis: auto;
          padding: 0;

          &:not(:first-child) {
            margin-top: var(--gutter-large);
          }
        }

        &-number {
          display: inline-block;
          font-weight: bold;
          font-size: var(--font-size-large);
          font-family: var(--font-new);

          @media (--medium) {
            display: block;
            font-size: var(--font-size-large-desktop);
          }
        }

        &-title {
          display: inline-block;
          font-weight: bold;
          font-family: var(--font-new);

          @media (--medium) {
            display: block;
          }
        }

        &-copy {
          margin-top: var(--gutter);
        }
      }

      @media (--medium) {
        flex: 0 0 50%;
        flex-direction: column;
        padding: 0 var(--gutter-xlarge) 0 0;
      }
    }

    &:nth-child(even) &-list {
      @media (--medium) {
        padding: 0 0 0 var(--gutter-xlarge);
      }
    }

    &:nth-child(even) {
      @media (--medium) {
        flex-direction: row;
      }
    }
  }
}
