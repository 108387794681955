/**
 * @define Toasts
 */

.Toasts {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  padding: 0;

  &-items {
    width: 100%;
    margin: 0 auto;

    @media (--medium) {
      width: var(--width-medium);
    }

    @media (--large) {
      width: var(--width-large);
    }
  }
}

.Toasts-item {
  position: relative;
  display: block;
  max-height: 80px;
  padding: var(--gutter);
  border-radius: var(--border-radius-toast);
  color: white;
  background-color: var(--colour-primary);
  transition: all var(--transition-default);

  &:first-child {
    margin-top: var(--gutter);
  }

  &:not(:last-child) {
    margin-bottom: var(--gutter);
  }

  &[data-type='message'] {
    border-color: var(--colour-primary);

    html.zd-theme-tenants & {
      background-color: var(--colour-main);
    }

    html.zd-theme-agents & {
      border-color: var(--colour-toast-success-border);
      background-color: var(--colour-toast-success-background);
    }

    a {
      color: var(--colour-toast-success-link);
    }
  }

  &[data-type='error'] {
    padding-right: var(--gutter-medium);
    color: white;
    background-color: var(--colour-error);
  }

  &-icon {
    display: block;
    float: left;
    margin-right: 20px;
    margin-bottom: var(--gutter-tiny);

    @media (--medium) {
      transform: scale(1.2);
    }
  }

  &-close {
    position: absolute;
    top: 50%;
    right: var(--gutter);
    float: right;
    color: white !important;
    font-size: 1.4em;
    text-decoration: none;
    transform: translateY(-50%);

    &:hover {
      color: color(white a(90%));
    }
  }

  &.is-new {
    transform: scale(1.1) translateY(-20px) translateZ(0);
    opacity: 0;
  }

  &.is-hidden {
    transform: scale(0.9) translateZ(0);
    opacity: 0;
  }

  &.is-swiped-left {
    transform: translateZ(0) translateX(-150px);
  }

  &.is-swiped-right {
    transform: translateZ(0) translateX(150px);
  }

  &.is-flat {
    max-height: 0;
    margin-bottom: 0;
    padding: 0;
  }
}
