/**
 * @define Footer
 */

.Footer {
  flex: 0 0 auto;
  width: 100%;
  margin-top: var(--gutter);
  background-color: var(--colour-grey-lighter);

  &-col {
    display: inline-block;
    vertical-align: top;

    &--social {
      margin-top: var(--gutter-large);

      @media (--medium) {
        margin-top: 0;
        text-align: right;
      }
    }
  }

  &-content {
    padding: var(--gutter);

    @media (--large) {
      padding: var(--gutter) 0;
    }
  }

  &-links-list {
    a {
      display: block;
      padding: var(--gutter-smaller) 0;
      color: var(--colour-brand);
      font-weight: var(--font-weight-headings);
      font-family: var(--font-headings);
      text-decoration: none;

      &:hover {
        border-radius: var(--border-radius-button);
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  &-social-links {
    li {
      display: inline-block;
    }

    a {
      padding: var(--gutter-smaller);
      color: var(--colour-brand);
      font-size: 1.3em;
      text-decoration: none;
      transition: color var(--transition-default);

      &:hover {
        color: var(--colour-primary);
      }

      html.zd-theme-tenants:hover {
        color: var(--colour-main);
      }

      html.zd-theme-agents:hover {
        color: var(--colour-agents);
      }
    }
  }

  ul {
    list-style-type: none;
  }
}
