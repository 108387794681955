/**
* @define LogoCarousel
*/

.LogoCarousel {
  &-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: var(--content-padding-large) var(--content-padding-mobile);

    @media (--mediumlarge) {
      padding: var(--content-padding-large-desktop)
        var(--content-padding-mobile);
    }
  }

  &-header {
    flex: 2 0 auto;
    width: 100%;

    @media (--large) {
      max-width: 800px;
    }

    @media (--xxxlarge) {
      max-width: 900px;
    }
  }

  &-slider {
    display: flex;
    justify-content: space-around;
    width: 100%;

    @media (--mediumlarge) {
      flex-direction: column;
      align-items: center;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (--mediumlarge) {
      flex-direction: row;
      align-items: flex-end;
      min-width: 500px;
      height: 40px;
    }
  }

  &-container:not(:last-child) {
    @media (--mediumlarge) {
      margin-bottom: 80px;
    }
  }
}
