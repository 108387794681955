/**
 * @define Link
 */

.Link,
a {
  color: var(--colour-primary);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:not([class]):hover {
    color: var(--colour-grey-mid);
  }

  html.zd-theme-agents &:not([class]) {
    color: var(--colour-agents);
  }

  html.zd-theme-tenants &:not([class]) {
    color: var(--colour-main);
  }
}
