/**
 * @define Header
 */

.Header {
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  color: white;
  background-color: var(--colour-brand);

  &-contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-help {
    @media (--medium) {
      position: absolute;
      top: 50%;
      right: calc(100% + var(--gutter));
      white-space: nowrap;
      transform: translateY(-50%);
    }
  }

  &-contact {
    position: relative;
    margin-left: var(--gutter);
    padding-left: var(--gutter);

    a {
      font-weight: normal;
      font-family: var(--font-headings);
      text-decoration: none;
    }

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 1px;
      background-color: white;
      content: '';
    }

    @media (--medium) {
      &::before {
        top: -15px;
        bottom: -15px;
      }
    }
  }

  /* currently we are updating only the tenant flow but we are going towards updating the style everywhere
  which is why the below is duplicated code from above and will eventually replace it */

  html.zd-theme-tenants & {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding-right: var(--gutter-large);
    padding-left: var(--gutter-large);
    color: white;
    background-color: var(--colour-brand-off-black);

    @media (--mediumlarge) {
      height: 100px;
      padding-right: var(--gutter-xlarge);
      padding-left: var(--gutter-xlarge);
    }

    &-contents {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-help {
      position: absolute;
      top: 50%;
      right: calc(100% + var(--gutter));
      color: white;
      font-weight: bold;
      font-size: var(--font-size-small);
      white-space: nowrap;
      transform: translateY(-50%);
    }

    &-contact {
      position: relative;
      margin-left: var(--gutter);
      padding-left: var(--gutter);
      color: var(--colour-main);
      font-weight: bold;
      font-size: var(--font-size-tiny);
      font-family: var(--font-new);

      a {
        font-weight: bold;
        font-family: var(--font-new) !important;
        text-decoration: none;
      }

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        background-color: white;
        content: '';
      }

      @media (--mediumlarge) {
        font-size: var(--font-size-small);
      }
    }
  }
}
