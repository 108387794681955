/**
 * @define Modal
 */

.Modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: color(#000 a(20%));
  transition: background-color var(--transition-default);
  user-select: none;
}

.Modal-ui {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 80vw;
  max-width: 100vw;
  border: var(--colour-grey-mid) 1px solid;
  border-radius: var(--border-radius-modal);
  overflow: hidden;
  background-color: white;
  box-shadow: 0 0 0 3px color(var(--colour-grey-mid) a(30%));
  transform: translateX(-50%) translateY(-50%) translateZ(0);
  transition: transform var(--transition-default),
    opacity var(--transition-default);

  @media (--medium) {
    width: 500px;
    min-width: inherit;
  }
}

.Modal-title {
  padding: var(--gutter-small);
  font-family: var(--font-headings);
  text-align: center;
  background-color: var(--colour-grey-lighter);
}

.Modal-body {
  padding: var(--gutter);
}

.Modal-controls {
  padding: 0 var(--gutter-small) var(--gutter-small);
  text-align: right;
}

.Modal.is-opening {
  background-color: color(#000 a(0%));
  pointer-events: none;

  .Modal-ui {
    transform: translateX(-50%) translateY(-25%) translateZ(0);
    opacity: 0;
  }
}

.Modal.is-closing {
  background-color: color(#000 a(0%));
  pointer-events: none;

  .Modal-ui {
    transform: translateX(-50%) translateY(-75%) translateZ(0);
    opacity: 0;
  }
}
