/**
* @define TextContent
*/

.TextContent {
  &--inverted {
    color: white;
    background-color: var(--colour-hook-inverted);
  }

  &-contents {
    padding-top: var(--gutter-xlarge);
    padding-bottom: var(--gutter-xlarge);

    @media (--large) {
      padding-top: var(--gutter-xxlarge);
      padding-bottom: var(--gutter-xxlarge);
    }
  }

  &-title {
    font-size: var(--font-size-hook);
    font-family: var(--font-new);

    @media (--large) {
      width: 50%;
      padding-right: var(--gutter-xlarge);
      font-size: var(--font-size-hook-desktop);
    }
  }

  &-body {
    flex: 1 0 100%;
    margin-top: var(--gutter-large);
    line-height: 1.7em;

    /* stylelint-disable plugin/selector-bem-pattern */

    h1,
    h2,
    h3 {
      margin-top: var(--gutter-large);
      font-weight: normal;
      font-family: var(--font-new);

      @media (--large) {
        margin-top: var(--gutter-xlarge);
      }
    }

    ul li:not(:first-child) {
      margin-top: var(--gutter-small);

      @media (--large) {
        margin-top: var(--gutter-medium);
      }
    }

    /* stylelint-enable plugin/selector-bem-pattern */

    :first-child {
      margin-top: 0 !important;
    }

    @media (--large) {
      font-size: var(--font-size-large);
    }
  }
}
