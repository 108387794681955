/**
 * @define RadioGroup
 */

.RadioGroup {
  &-controls {
    display: block;
  }

  /* helpers (errors and hint text) */

  &-helpers {
    position: relative;
    z-index: 0;
    max-height: 0;
    overflow: hidden;
    font-size: var(--font-size-small);
    transition: max-height var(--transition-default);

    &::after {
      left: 0;
      display: block;
      width: 100%;
      padding: var(--gutter-smaller) 0;
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      font-weight: normal !important;
      font-size: var(--font-size-small);
      font-family: var(--font-headings);
      line-height: 1em;
      transition: opacity var(--transition-default);
      content: var(--unicode-nbsp);
      pointer-events: none;
    }

    html.zd-theme-tenants &::after {
      font-family: var(--font-new);
    }

    &[data-hint]:not([data-hint='']) {
      max-height: 50px;

      &::after {
        color: var(--colour-grey-mid-dark);
        content: attr(data-hint);
      }
    }
  }

  &.is-error,
  html.zd-theme-tenants &.is-error,
  html.zd-theme-agents &.is-error {
    .RadioGroup-input {
      border-color: var(--colour-error);
    }

    .RadioGroup-helpers[data-error]:not([data-error='']) {
      max-height: 50px;

      &::after {
        color: var(--colour-error);
        content: attr(data-error);
      }
    }
  }
}
