/**
* @define CookieBar
*/

.CookieBar {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  flex: 2 0 auto;
  width: 100%;
  overflow: hidden;
  color: white;
  background-color: var(--colour-brand-dark);

  &-contents {
    max-width: var(--width-large);
    margin: 0 auto;
    padding: var(--gutter);

    @media (--large) {
      padding: var(--gutter);
    }
  }

  a {
    color: color(var(--colour-main) lightness(50%));
    text-decoration: none;
    transition: var(--transition-default) color;

    &:hover {
      color: white !important;
    }
  }

  &-close {
    font-size: 20px;
    transform: translateY(-6px);

    @media (--large) {
      display: block;
      float: right;
      font-size: 26px;
      transform: translateY(-8px);
    }
  }
}
