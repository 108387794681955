@define-mixin buttonSizing {
  min-width: 130px;
  padding: var(--button-padding);
  white-space: nowrap;

  @media (--medium) {
    min-width: 150px;
  }

  @media (--large) {
    min-width: 180px;
  }
}

@define-mixin buttonBoxShadow $color {
  box-shadow: 0 0 0 3px color($(color) a(50%));
}

@define-mixin buttonInteraction {
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  /* anchor button */

  a& {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
}

/* extended hitarea */

@define-mixin buttonHitarea {
  &::after {
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    background-color: rgba(0, 0, 0, 0);
    content: '';
  }
}

@define-mixin button $name {
  .$(name) {
    @mixin buttonSizing;

    @mixin buttonInteraction;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    border: var(--colour-grey-mid) 1px solid;
    border-radius: var(--border-radius-button);
    overflow: hidden;
    font-size: var(--font-size-normal);
    font-family: var(--font-headings);
    text-align: center;
    vertical-align: bottom;
    background-color: white;
    box-shadow: 0 0 0 0 color(white a(0%));
    transition: background-color var(--transition-default),
      box-shadow var(--transition-default);

    &[class*='Icons-'] {
      &::before {
        margin-right: 8px;
        vertical-align: top;
      }

      &::after {
        margin-left: 8px;
        vertical-align: top;
      }
    }

    /* state */

    &:hover {
      border-color: var(--colour-grey-mid-dark);
    }

    &:focus {
      @mixin buttonBoxShadow var(--colour-grey-mid);
    }

    &:active {
      box-shadow: unset;
    }

    /* ripple */

    &-ink {
      position: absolute;
      display: block;
      border-radius: 50%;
      color: red;
      background-color: var(--colour-grey-light);
      transform: scale(0) translateZ(0);
      opacity: 0.4;
      pointer-events: none;

      &.animate {
        animation: ripple 0.6s ease;
      }
    }

    /* modifiers */

    &--primary,
    &--primary-forced,
    &--tertiary,
    &--tenants,
    &--agents {
      border-color: var(--colour-brand);
      background-color: var(--colour-brand);

      &:hover {
        border-color: color(var(--colour-brand) lightness(20%));
        background-color: color(var(--colour-brand) lightness(20%));
      }

      .$(name)-ink {
        background-color: color(white a(70%));
      }
    }

    &--main {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 6.625rem;
      height: 2.5rem;
      min-height: 1.875rem;
      padding: 0;
      border: none;
      color: #fff !important;
      font-weight: bold;
      font-family: var(--font-new);
      background-color: var(--colour-main);
      cursor: pointer;

      /* states */

      &:hover,
      &:focus,
      &:active {
        background-color: var(--colour-accent);
        box-shadow: none;
      }

      &:hover .is-disabled {
        background-color: var(--colour-grey-disabled) !important;
      }

      .$(name)-ink {
        background-color: color(white a(70%));
      }

      &:disabled,
      &.is-disabled {
        background-color: var(--colour-grey-disabled) !important;
        cursor: not-allowed;
        opacity: 0.8;
      }

      @media (--mediumlarge) {
        max-width: 25rem;
      }
    }

    html.zd-theme-agents & {
      color: var(--colour-agents);
    }

    &--primary,
    &--primary-forced {
      color: var(--colour-primary-button) !important;
    }

    &--tertiary {
      color: white !important;
    }

    &--agents,
    html.zd-theme-agents &--primary {
      color: var(--colour-agents-button) !important;
    }

    &--link {
      border: 0;
      background-color: transparent;

      &:focus {
        background-color: white;
        box-shadow: none;
      }

      &:hover {
        color: var(--colour-primary);
        text-decoration: underline;

        html.zd-theme-tenants & {
          color: var(--colour-main);
        }

        html.zd-theme-agents & {
          color: var(--colour-agents);
        }
      }
    }

    /* size modifiers */

    &--large {
      min-width: 210px;
      padding: var(--button-padding-large);
      font-size: 18px;
    }

    &--small {
      min-width: 100px;
      padding: var(--button-padding-small);
      font-size: 13px;

      @media (--medium) {
        min-width: 125px;
      }

      @media (--large) {
        min-width: 150px;
      }
    }

    &--span {
      flex: 2 0 auto;
      width: 100%;
    }

    &--flex-half {
      flex: 2 1 100%;

      &:not(:first-child) {
        margin-top: var(--gutter-small);
      }

      @media (--large) {
        flex-basis: 50%;
        margin-top: 0 !important;
        margin-right: var(--gutter-small);
      }
    }

    /* temporary new size modifiers to become default */

    &--s {
      width: 6.625rem;
      height: 1.875rem;
      font-size: 0.75rem;
    }

    &--m {
      width: 8.3125rem;
      height: 2.5rem;
      font-size: 0.875rem;
    }

    &--l {
      width: 12.5rem;
      height: 3.125rem;
      font-size: 1.125rem;
    }

    &--xl {
      width: 15.625rem;
      height: 3.4375rem;
      font-size: 1.125rem;
    }

    &:disabled,
    &.is-disabled {
      cursor: not-allowed;
      opacity: 0.8;
    }

    a&.is-disabled {
      pointer-events: none;
    }

    /* margins for series of buttons */

    &:not(:last-child):not(.u-block):not(.Button--flex-half) {
      margin-right: var(--gutter-small);
    }
  }
}

@keyframes ripple {
  100% {
    transform: scale(2.5) translateZ(0);
    opacity: 0;
  }
}
