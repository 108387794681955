/**
 * @define ModalSignUp
 */

.ModalSignUp {
  position: relative;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 800px;
  height: 600px;
  background-color: white;
  transform: translateX(-50%) translateY(-50%) translateZ(0);
  opacity: var(--transition-default);
  transition: transform var(--transition-default);

  @media (max-width: 640px) {
    width: 100vw;
    height: 100vh;
  }

  @media (max-height: 600px) {
    height: 100vh;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    max-height: 720px;
    overflow: auto;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 10%;
    min-height: 80px;

    @media (max-width: 400px) {
      margin-top: 20px;
    }
  }

  &-pageContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    min-height: 500px;
    padding: 0 12% 8%;
    animation: fade 0.25s ease-in-out;

    @media (max-width: 420px) {
      padding: 0 8% 8%;
    }
  }

  &-title {
    margin-top: 0 !important;
    margin-bottom: 1.5rem;
    font-size: 1.75rem;

    @media (max-width: 860px) {
      color: #444;
    }
  }

  &-formTitle {
    @media (max-width: 860px) {
      color: #444;
      font-weight: normal;
      font-size: 1.125rem;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  &-Input {
    width: 100%;
  }

  &-InputGroup {
    margin-top: 7%;
  }

  &-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #979797;
    font-size: 0.75rem;
  }

  &-footer-text {
    max-width: 100%;
    font-weight: 400;
  }

  &-finish {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }
}

.ArrowCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 95px;
  padding: 5% 0 5% 0%;
  cursor: pointer;
  transition: padding var(--transition-default);

  &:not(:last-child) {
    border-bottom: 1px dashed #d8d8d8;
  }

  &:hover {
    padding: 4% 3% 4% 3.5%;
    background-color: #f7f7f7;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: padding var(--transition-default);
  }

  &:hover:not(:last-child) {
    border-bottom: 1px solid #f7f7f7;
  }
}

.ArrowCard-text {
  color: #9d9d9d;
  font-weight: 400;
  font-size: 1rem;
}

.ArrowCard-textContainer {
  margin-right: 10px;
}

.ModalSignUp-Icons-next {
  color: var(--colour-main);
  font-size: 1.87rem;
}

.ModalSignUp-back {
  margin: 1rem;
}

.ModalSignUp-close,
.ModalSignUp-back {
  margin: 1rem;
  cursor: pointer;

  @media (max-width: 750px) {
    margin: 7%;
  }
}

.ModalSignUp-link {
  color: var(--colour-main);
}

.ModalSignUp-text {
  margin-top: 3.125rem;
  color: var(--colour-brand-off-black);
  font-size: 1.5rem;

  @media (max-width: 860px) {
    font-size: 1.125rem;
  }
}

.ModalSignUp-button {
  width: 300px;
  width: 100%;
  min-width: 300px;
  height: 55px;
  min-height: 55px;
  color: white !important;
  font-size: 1.125rem;
  text-transform: capitalize;
  background-color: var(--colour-main);
  cursor: pointer;
}

.ModalSignUp-radioWrap {
  margin-bottom: var(--gutter);
  font-weight: 400;
}

.ModalSignUp-hidden {
  display: none;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
