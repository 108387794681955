/**
* @define FeatureImage
*/

.FeatureImage {
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: var(--content-padding-large);

  &-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: var(--content-padding-large) var(--content-padding-mobile)
      var(--content-padding-titles);

    @media (--xlarge) {
      width: 50%;
      padding: 3.75rem;
    }

    @media (--xxlarge) {
      padding: 10.375rem;
    }
  }

  &-title,
  &-copy {
    width: 100%;
  }

  &-title,
  &-copy {
    @media (--xlarge) {
      max-width: 25rem;
    }
  }

  @media (--xlarge) {
    flex-direction: row;
    height: 100vh;
    min-height: 500px;
  }
}
