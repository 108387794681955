@define-mixin inputSimple {
  width: 100%;
  padding: var(--gutter-small) 0;
  border: 0;
  border-bottom: var(--colour-grey-light) 1px solid;
  background-color: transparent;
  outline: none;
  transition: all var(--transition-default);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@define-mixin inputBoxShadow $color {
  box-shadow: 0 0 0 3px color($(color) a(10%));
}
