:root {
  --icon-burger: "\f101";
  --icon-calendar: "\f102";
  --icon-cc-amex: "\f103";
  --icon-cc-default: "\f104";
  --icon-cc-diners: "\f105";
  --icon-cc-discover: "\f106";
  --icon-cc-jcb: "\f107";
  --icon-cc-mastercard: "\f108";
  --icon-cc-visa: "\f109";
  --icon-close: "\f10a";
  --icon-context-menu: "\f10b";
  --icon-delete: "\f10c";
  --icon-direct-debit: "\f10d";
  --icon-down: "\f10e";
  --icon-edit: "\f10f";
  --icon-email: "\f110";
  --icon-error: "\f111";
  --icon-expand-down: "\f112";
  --icon-expand-up: "\f113";
  --icon-fattick: "\f114";
  --icon-file: "\f115";
  --icon-folder-open: "\f116";
  --icon-folder: "\f117";
  --icon-grid3x3: "\f118";
  --icon-help: "\f119";
  --icon-menu-down: "\f11a";
  --icon-next-step: "\f11b";
  --icon-next: "\f11c";
  --icon-power: "\f11d";
  --icon-prev-step: "\f11e";
  --icon-prev: "\f11f";
  --icon-resend: "\f120";
  --icon-search: "\f121";
  --icon-settings: "\f122";
  --icon-social-facebook: "\f123";
  --icon-social-linkedin: "\f124";
  --icon-social-twitter: "\f125";
  --icon-tick-inverse: "\f126";
  --icon-tick: "\f127";
  --icon-tool-bell: "\f128";
  --icon-tool-help: "\f129";
  --icon-trash: "\f12a";
  --icon-up: "\f12b";
  --icon-video-play: "\f12c";
  --icon-view: "\f12d";
}

@font-face {
  font-family: "Icons";
  src: url("./fonts/Icons.eot?1d838c7ad92517d561ef0c4a668aa6eb?#iefix") format("embedded-opentype"),
url("./fonts/Icons.woff2?1d838c7ad92517d561ef0c4a668aa6eb") format("woff2"),
url("./fonts/Icons.woff?1d838c7ad92517d561ef0c4a668aa6eb") format("woff"),
url("./fonts/Icons.ttf?1d838c7ad92517d561ef0c4a668aa6eb") format("truetype"),
url("./fonts/Icons.svg?1d838c7ad92517d561ef0c4a668aa6eb#Icons") format("svg");
}

[class*=Icons-]:before {
  font-family: Icons !important;
  font-style: normal;
  font-weight: normal !important;
  vertical-align: top;
  z-index: 2;
}

.Icons-burger:before {
  content: "\f101";
}
.Icons-calendar:before {
  content: "\f102";
}
.Icons-cc-amex:before {
  content: "\f103";
}
.Icons-cc-default:before {
  content: "\f104";
}
.Icons-cc-diners:before {
  content: "\f105";
}
.Icons-cc-discover:before {
  content: "\f106";
}
.Icons-cc-jcb:before {
  content: "\f107";
}
.Icons-cc-mastercard:before {
  content: "\f108";
}
.Icons-cc-visa:before {
  content: "\f109";
}
.Icons-close:before {
  content: "\f10a";
}
.Icons-context-menu:before {
  content: "\f10b";
}
.Icons-delete:before {
  content: "\f10c";
}
.Icons-direct-debit:before {
  content: "\f10d";
}
.Icons-down:before {
  content: "\f10e";
}
.Icons-edit:before {
  content: "\f10f";
}
.Icons-email:before {
  content: "\f110";
}
.Icons-error:before {
  content: "\f111";
}
.Icons-expand-down:before {
  content: "\f112";
}
.Icons-expand-up:before {
  content: "\f113";
}
.Icons-fattick:before {
  content: "\f114";
}
.Icons-file:before {
  content: "\f115";
}
.Icons-folder-open:before {
  content: "\f116";
}
.Icons-folder:before {
  content: "\f117";
}
.Icons-grid3x3:before {
  content: "\f118";
}
.Icons-help:before {
  content: "\f119";
}
.Icons-menu-down:before {
  content: "\f11a";
}
.Icons-next-step:before {
  content: "\f11b";
}
.Icons-next:before {
  content: "\f11c";
}
.Icons-power:before {
  content: "\f11d";
}
.Icons-prev-step:before {
  content: "\f11e";
}
.Icons-prev:before {
  content: "\f11f";
}
.Icons-resend:before {
  content: "\f120";
}
.Icons-search:before {
  content: "\f121";
}
.Icons-settings:before {
  content: "\f122";
}
.Icons-social-facebook:before {
  content: "\f123";
}
.Icons-social-linkedin:before {
  content: "\f124";
}
.Icons-social-twitter:before {
  content: "\f125";
}
.Icons-tick-inverse:before {
  content: "\f126";
}
.Icons-tick:before {
  content: "\f127";
}
.Icons-tool-bell:before {
  content: "\f128";
}
.Icons-tool-help:before {
  content: "\f129";
}
.Icons-trash:before {
  content: "\f12a";
}
.Icons-up:before {
  content: "\f12b";
}
.Icons-video-play:before {
  content: "\f12c";
}
.Icons-view:before {
  content: "\f12d";
}
