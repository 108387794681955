/**
* @define NumberStepper
*/

.NumberStepper {
  display: inline-flex;
  align-items: stretch;
  justify-content: start;

  &-dn,
  &-up {
    @mixin buttonInteraction;
    display: flex;

    align-items: center;
    justify-content: center;
    width: 30px;
    height: 40px;
    padding: 0 var(--gutter-large);
    border: var(--colour-grey-light) 1px solid;
    border-radius: var(--border-radius-button);
    color: var(--colour-primary);
    font-weight: bold;
    font-size: var(--font-size-normal);
    cursor: pointer;
    transition: background-color 0.5s ease;
    user-select: none;

    &:hover {
      background-color: var(--colour-grey-lighter);
    }

    &:active {
      box-shadow: inset 0 0 5px var(--colour-grey-light);
    }

    html.zd-theme-tenants & {
      color: var(--colour-main);
    }

    html.zd-theme-agents & {
      color: var(--colour-agents);
    }
  }

  &-dn {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &-up {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &-value {
    width: 100px;
    border: var(--colour-grey-light) 1px solid;
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      appearance: none;
    }

    &:focus {
      outline: none;
    }
  }
}
