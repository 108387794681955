/**
* @define FeatureList
*/

.FeatureList {
  padding: 6.25rem 0;

  &-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: var(--gutter-xlarge);

    @media (--xlarge) {
      flex-direction: row;
      height: 600px;
      margin: 135px 0 var(--gutter-xlarge) 0;
    }
  }

  & .title-wrap .subtitle {
    @media (--xlarge) {
      text-align: center;
    }
  }

  &-image-left {
    @media (--xlarge) {
      clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
    }
  }

  &-row-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding-right: 1.875rem;
    padding-left: 1.875rem;

    @media (--xlarge) {
      width: 50%;
      padding-right: 0;
      padding-left: 8%;
    }

    .Icons-fattick {
      padding-right: var(--content-padding-mobile);
      color: var(--colour-main);
      font-size: 1.25rem;

      @media (--small) {
        font-size: 1.56rem;
      }
    }
  }

  &-row-list-left {
    @media (--xlarge) {
      order: -1;
      padding-right: 8%;
      padding-left: 0;
    }
  }

  &-row-item-title {
    margin-bottom: 2.25rem;
    font-weight: 500;
    font-size: var(--font-size-copy);

    @media (--small) {
      font-weight: 500;
      font-size: var(--font-size-large-desktop);
    }

    @media (--xlarge) {
      margin-bottom: 3.125rem;
    }
  }

  &-row-item-title:first-child {
    margin-top: 0;
  }

  &-row-item {
    width: 100%;
  }

  &-row-item:not(:last-child) {
    margin-bottom: var(--gutter-xlarge);
  }

  @media (--xlarge) {
    padding: var(--content-padding-xlarge) var(--content-padding-desktop);
  }

  @media (--xxlarge) {
    padding: var(--content-padding-xlarge) var(--content-padding-larger-desktop);
  }

  &--ticks {
    .FeatureList-row-item-copy {
      padding-left: var(--content-padding-desktop);
    }
  }

  &--count {
    .FeatureList-row-list {
      counter-reset: item;
    }

    .FeatureList-row-item-copy {
      padding-left: var(--content-padding-titles);

      @media (--small) {
        padding-left: var(--content-padding-desktop);
      }
    }

    .FeatureList-row-item-title {
      margin-bottom: 1.875rem;
      counter-increment: item;

      &::before {
        display: inline-block;
        width: var(--content-padding-titles);
        color: var(--colour-main);
        font-weight: 700;
        font-size: 1.875rem;
        vertical-align: sub;
        content: counter(item, decimal-leading-zero);

        @media (--small) {
          width: var(--content-padding-desktop);
          font-size: 2.5rem;
        }
      }
    }
  }
}
