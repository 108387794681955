/**
 * @define Page
 */

.PageContents {
  display: flex;
  flex: 2 0 auto;
  flex-direction: column;
  justify-content: space-between;
  transition: filter var(--transition-default);

  /* Fill void after contents */

  &::after {
    position: relative;
    flex: 3 0 auto;
    content: '';
  }

  &--tooling {
    justify-content: flex-start;
    padding-bottom: 50px;
  }
}
