/**
 * @define Hero
 */

.Hero {
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--xlarge) {
      position: relative;
      flex-wrap: wrap;
      justify-content: center;
      height: calc(100vh - 110px);
      min-height: 570px;
      overflow-x: hidden;
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: var(--content-padding-mobile);
    font-size: var(--font-size-hook-desktop);

    @media (--small) {
      padding: var(--content-padding-titles) var(--content-padding-mobile);
    }

    @media (--mediumlarge) {
      padding: var(--content-padding-large) var(--content-padding-mobile)
        var(--content-padding-titles);
    }

    @media (--xlarge) {
      align-self: flex-start;
      max-width: 20.625rem;
      margin: 0 var(--content-padding-large) 0 var(--content-padding);
      padding: 0;
    }

    @media (--xxxlarge) {
      margin-left: var(--content-padding-large);
    }

    &-highlight {
      color: var(--colour-main);
    }
  }

  &-title {
    margin-bottom: var(--content-padding-mobile);
    font-weight: bold;
    font-size: var(--font-size-hook-desktop);
    font-family: var(--font-new);
    line-height: var(--font-size-hook-desktop);
    text-align: center;

    @media (--small) {
      margin-bottom: var(--content-padding-titles);
      font-size: 2.5rem;
      line-height: var(--font-size-hero);
    }

    @media (--mediumlarge) {
      font-size: var(--font-size-hero);
    }

    @media (--xlarge) {
      text-align: left;
    }
  }

  &-copy {
    color: var(--colour-brand-off-black);
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-hero-copy-small);
    line-height: var(--font-size-hook-desktop);
    text-align: center;

    @media (--small) {
      font-size: var(--font-size-large-desktop);
      line-height: 2.25rem;
    }

    @media (--xlarge) {
      max-width: 20.625rem;
      margin-bottom: var(--content-padding-titles);
      text-align: left;
    }
  }

  &-image {
    width: 100%;
    height: 300px;
    min-height: 300px;
    background-position: 50% 50%;
    background-size: cover;

    @media (--small) {
      height: 500px;
      min-height: 500px;
    }

    @media (--xlarge) {
      width: 75%;
      height: 100%;
      background-position: 25% 50%;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
    }
  }

  &-cta-wrap {
    order: 1;
    margin-top: var(--content-padding-mobile);
    margin-bottom: 1.3125rem;

    @media (--xlarge) {
      align-self: flex-start;
      order: 0;
      margin-top: 0;
      margin-left: var(--content-padding);
    }

    @media (--xxxlarge) {
      margin-left: var(--content-padding-large);
    }
  }

  &-link-wrap {
    order: 2;
    width: 100%;
    padding-top: var(--content-padding-small);
    padding-bottom: var(--content-padding-small);
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;

    & a:first-child {
      display: flex;
      height: 1.25rem;
    }

    /* stylelint-disable */

    & p:first-child {
      width: 12.5rem;
      margin: 0 auto;
    }

    & img:first-child {
      width: 100%;
    }

    /* stylelint-enable */

    @media (--xlarge) {
      position: absolute;
      bottom: 0;
      left: 0;
      align-self: flex-start;
      order: 0;
      width: 25%;
      margin-left: var(--content-padding);
      padding-bottom: var(--content-padding);
      border: none;

      /* stylelint-disable */

      & p:first-child {
        margin: 0;
      }

      /* stylelint-enable */
    }

    @media (--xxxlarge) {
      margin-left: var(--content-padding-large);
    }
  }
}
