/** @define utilities */

.u-hidden {
  display: none !important;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-capitalize {
  text-transform: capitalize;
}

/* font weight */

.u-bold {
  font-weight: 600;
}

.u-light {
  font-weight: 300;
}

/* Margin top helpers */

.u-push-1 {
  margin-top: var(--gutter-small);
}

.u-push-2 {
  margin-top: var(--gutter);
}

.u-push-3 {
  margin-top: var(--gutter-large);
}

.u-push-4 {
  margin-top: var(--gutter-xlarge);
}

.u-push-large {
  margin-top: calc(var(--gutter-large) * 2);
}

.u-push-xlarge {
  margin-top: calc(var(--gutter-xlarge) * 2);
}

/* Vertical alignment */

.u-valign-top {
  vertical-align: top;
}

.u-valign-middle {
  vertical-align: middle;
}

.u-valign-bottom {
  vertical-align: bottom;
}

/* element display type overrides */

.u-block {
  display: block;
}

.u-inline-block {
  display: inline-block;
}

.u-flex {
  display: flex;
}

.u-inline-flex {
  display: inline-flex;
}

.u-flex-expand {
  flex: 2 0 auto;
}

/* float */

.u-margin-auto {
  margin: 0 auto;
}

.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

/* clearfix */

.u-clearfix::after {
  display: table;
  clear: both;
  content: '';
}

/* flexbox utils */

.u-flex-filler {
  flex: 2 1 auto;
}

/* force hardware rendering (GPU) */

.u-accel {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

/* text colours */

.u-col-brand {
  color: var(--colour-brand);
}

.u-col-primary {
  color: var(--colour-primary);

  html.zd-theme-tenants & {
    color: var(--colour-main);
  }

  html.zd-theme-agents & {
    color: var(--colour-agents);
  }
}

.u-col-primary-forced {
  color: var(--colour-primary);
}

.u-col-tenants {
  color: var(--colour-main);
}

.u-col-agents {
  color: var(--colour-agents);
}

.u-col-main {
  color: var(--colour-main);
}

.u-col-zd-black {
  color: var(--colour-brand-off-black);
}

.u-col-error {
  color: var(--colour-error);
}

/* tranition on all props */

.u-transition {
  transition: all var(--transition-default);
}
