@mixin checkbox CheckBox {
  &-input + &-label {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: var(--font-size-input);
      height: var(--font-size-input);
      margin-top: -3px;
      margin-right: var(--gutter-small);
      border: var(--colour-grey-light) 2px solid;
      border-radius: var(--border-radius-checkbox);
      color: transparent;
      font-weight: normal;
      font-family: Icons !important;
      text-align: center;
      vertical-align: middle;
      vertical-align: text-top;
      transition: border-color var(--transition-default),
        background-color var(--transition-default);
      content: var(--icon-tick);
    }
  }

  &-label:hover::before {
    border-color: var(--colour-grey-dark);
  }

  &-input:checked + &-label::before {
    color: white;
    background-color: var(--colour-primary);
  }

  html.zd-theme-tenants &-input:checked + &-label::before {
    background-color: var(--colour-main);
  }

  html.zd-theme-agents &-input:checked + &-label::before {
    background-color: var(--colour-agents);
  }

  &.is-error,
  html.zd-theme-tenants &.is-error,
  html.zd-theme-agents &.is-error {
    .CheckBox-input:checked + .CheckBox-label::before {
      background-color: var(--colour-error);
    }
  }
}
