@mixin checkbox Radio {
  &-input + &-label {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      box-sizing: border-box;
      width: var(--font-size-input);
      height: var(--font-size-input);
      margin-top: -3px;
      margin-right: var(--gutter-small);
      padding-top: 2px;
      border: var(--colour-grey-light) 2px solid;
      border-radius: 50%;
      font-weight: normal;
      text-align: center;
      vertical-align: middle;
      vertical-align: text-top;
      transition: all var(--transition-default);
      content: '';
    }
  }

  &-label:hover::before {
    border-color: var(--colour-grey-dark);
  }

  &-input:checked + &-label::before {
    border-width: 7px;
    background-color: transparent;
  }

  &.is-error,
  html.zd-theme-tenants &.is-error,
  html.zd-theme-agents &.is-error {
    .CheckBox-input + .CheckBox-label::before {
      border-color: var(--colour-error);
    }

    .CheckBox-helpers[data-error]:not([data-error=''])::after {
      color: var(--colour-error);
      content: attr(data-error);
    }
  }
}
